<template>
  <!--  上传查看明细-->
  <div style=" margin: -15px -15px;overflow: hidden;">
    <a-spin :spinning="spinning">
      <div style="position: relative">
      <div class="topOne">
        <div style="display: flex">
          <div class="topOne-title">{{this.$route.query.title}}</div>
          <div v-if="LiveInfoIsSahow" class="topOne-btn" style="border: 1px solid #B7EB8F;color: #52C41A;background-color: #F6FFED;">
            海报视频已上传
          </div>
          <div @click="upLiveInfo" v-if="!LiveInfoIsSahow" class="topOne-btn" style="border: 1px solid #FFA39E;color: #F5222D;background-color: #FEF0EF;">
            海报视频未上传
          </div>
        </div>
        <div>
          <span class="time">会议时间：{{this.$route.query.time}}</span>
        </div>
      </div>
      <div class="liveMeet-top">
        <div class="liveMeet-top-input">
          <div>姓名:</div>
          <a-input
              @pressEnter="pressEnter"
              v-model="titleData"
              class="input"
              placeholder="请输入" />
        </div>
        <div class="liveMeet-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <div class="activity-table">
        <div class="activity-table-btn">
          <a-button
              @click="addBtn"
              type="primary">
            新增
          </a-button>
          <div style="display: flex;margin-right: 50px">
            <div class="aleart">
              <a-icon
                  style="background-color: #1890ff;
                  height: 14px;width: 14px;
                  border-radius: 14px;
                  color: #e8e8e8"
                  type="exclamation-circle" />
              <span style="padding-right: 20px">
<!--                {{MtOtDetail}}-->
                <span>总金额：{{MtOtDetail.tolMoney}}元</span>
<!--                0:待审批 1:已通过 2:已驳回 3:打款成功 4:打款失败-->
                <span style="margin-left: 10px;">总数：<span @click="stateBtn('')" style="color: #0E77D1;cursor: pointer">{{MtOtDetail.tolMember}}</span></span>
                <span style="margin-left: 10px">待审批：<span @click="stateBtn(0)" style="color: #FFBF52;cursor: pointer" >{{MtOtDetail.reviewedCount}}</span></span>
                <span style="margin-left: 10px">待打款：<span @click="stateBtn(1)" style="color: #0E77D1;cursor: pointer">{{MtOtDetail.noPayCount}}</span></span>
                <span style="margin-left: 10px">已驳回：<span @click="stateBtn(2)" style="color: #F04134;cursor: pointer">{{MtOtDetail.rejectCount}}</span></span>
                <span style="margin-left: 10px">已打款：<span @click="stateBtn(3)" style="color: #00A854;cursor: pointer">{{MtOtDetail.payCount}}</span></span>
                <span style="margin-left: 10px">打款失败：<span @click="stateBtn(4)" style="color: #F04134;cursor: pointer">{{MtOtDetail.payFailCount}}</span></span>
              </span>
            </div>
            <a-button @click="ExportBulk"><a-icon style="color: #00A854;" type="file-excel" />批量导出</a-button>
          </div>

        </div>
        <a-table
            style="width: 99%;margin: auto"
            :rowKey="record=>record.id"
            :pagination="false"
            :columns="columns"
            :data-source="data">
          <span slot="system" slot-scope="text, record">
            <!--   0:待审批 1:已通过 2:已驳回 3:打款成功 4:打款失败   -->
            <!--   1.判断当前登录的用户是资料负责人还是创建人, 同时符合时显示创建人权限的按钮        -->

            <span v-if="userId*1 === createdById*1">
              <!--创建人-->
              <a v-if="record.state === 3 || record.state === 1 || record.state === 2 || record.state === 4" @click="lookPer(record)">查看</a>
              <a v-if="record.state === 2 || record.state === 4" @click="compileBtn(record)" style="margin-left: 5px;">编辑</a>
              <a-popconfirm
                v-if="record.state === 2"
                style="margin-left: 5px;"
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="confirm(record.id)"><a>删除</a></a-popconfirm>
              <a v-if="record.state === 0 || record.state === 2" @click="reCheckBtn(record)" style="margin-left: 5px;">复核</a>
              <a v-if="record.state === 1" @click="UploadCredentials(record)" style="margin-left: 5px;">上传凭证</a>
            </span>

            <span v-else>
              <!--资料负责人-->
              <a v-if="record.state !== 2 || record.state === 4" @click="lookPer(record)">查看</a>
              <a v-if="record.state === 2 || record.state === 4" @click="compileBtn(record)" style="margin-left: 5px;">编辑</a>
              <a-popconfirm
                  v-if="record.state === 2"
                  style="margin-left: 5px;"
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirm(record.id)"><a>删除</a></a-popconfirm>
            </span>
          </span>
          <span slot="status" slot-scope="text, record">
<!--            0:待审批 1:已通过 2:已驳回 3:打款成功 4:打款失败-->
<!--            3:打款成功-->
            <div v-if="record.state === 3"><admin_con style="color: #00A854;" class="icon" type="icon-yuandian1"/>已打款</div>
<!--            1:已通过 -->
            <div v-if="record.state === 1"><admin_con style="color: #0E77D1;" class="icon" type="icon-yuandian1"/>审核通过待打款</div>
<!--            2:已驳回-->
            <div v-if="record.state === 2"><admin_con style="color: #F04134;" class="icon" type="icon-yuandian1"/>已驳回</div>
<!--            4:打款失败-->
            <div v-if="record.state === 4"><admin_con style="color: #F04134;" class="icon" type="icon-yuandian1"/>打款失败</div>
<!--            0:待审批-->
            <div v-if="record.state === 0"><admin_con style="color: #FFBF52;" class="icon" type="icon-yuandian1"/>待审核</div>
          </span>
        </a-table>
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 15px">
            <a-pagination
                :current="page_no"
                @change="paginationBtn"
                :total="tableCount" />
          </div>
        </div>
      </div>
      <!-------------------对话框----------------->
<!--        会议资料-->
      <a-drawer
          title="会议材料"
          placement="right"
          :closable="false"
          :visible="visible"
          width="550px"
          @close="closeDrawer"
      >
        <a-form-model
            ref="ruleFormHui"
            :model="form"
            :rules="rules"
            :label-col="labelCol1"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="海报" prop="descImageUrl">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="descImageUrlId"
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="upDescImageUrl"
                    ref="descImageUrl" />
              </div>
              <div class="upload_poster" v-if="form.descImageUrl" v-for="itemD in form.descImageUrl">
                <img
                    alt=""
                    style="width: 100%;height:100%"
                    :src="itemD"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="讲课视频" prop="video">
            <div class="uploadVideo" >
              <a-icon type="upload" /> 上传文件
            </div>
              <input
                  id="up_video"
                  style="width:120px;height: 40px;opacity: 0;position: absolute;top: 0;"
                  type="file" accept=".mp4"
                  @change="upVideo($event)"/>
<!--              <div class="upVideoTitle"><a-icon type="upload" /> 上传文件</div>-->
<!--            </div>-->
            <div style="color: #8c8a8a;">支持扩展名：mp4</div>
            <div style="color: #8c8a8a;margin-bottom: 5px;font-size: 10px" v-if="form.video" v-for="(itemV,index) in form.video">
              <a-icon type="link" style="font-size: 14px;margin-right: 5px"/>
              {{itemV}}
              <a-icon @click="reduceBtnVideo(index)" style="margin-left: 10px;font-size: 16px;cursor: pointer" type="close-circle" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="会议小结" prop="meet">
            <a-textarea v-model="form.meet" placeholder="请输入" :rows="6" />
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }">
          <a-button style="marginRight: 8px" @click="closeDrawer">
            取消
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
<!--        新增/编辑-->
      <a-drawer
            :title="titleVisible"
            placement="right"
            :closable="false"
            :visible="addVisible"
            width="600px"
            @close="closeAddDrawerClose"
        >
          <div style="width: 100%;overflow:hidden;">
            <a-form-model
                ref="ruleFormAdd"
                :model="addForm"
                :rules="addRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
              <div style="height: 50px;font-size: 16px;font-weight: bold;">基本信息</div>
              <a-form-model-item label="手机号" prop="tel">
                <a-input-search
                    v-model="addForm.tel"
                    style="width: 350px"
                    placeholder="请输入"
                    enter-button="搜索"
                    size="large"
                    @search="onSearch"
                />
              </a-form-model-item>
              <a-form-model-item label="姓名" prop="name">
                <a-input :disabled="isShowBtn" style="width: 350px" v-model="addForm.name" placeholder="请输入"/>
              </a-form-model-item>
              <a-form-model-item label="身份证号" prop="idCard">
                <a-input :disabled="isShowBtn" style="width: 350px" v-model="addForm.idCard" placeholder="请输入"/>
              </a-form-model-item>
              <a-form-model-item label="医院" prop="hospitalName">
                <a-select
                    :disabled="isShowBtn"
                    v-model="addForm.hospitalName"
                    @popupScroll="popupScroll"
                    @search="selectAddSearch"
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    style="width: 350px"
                ><a-select-option
                    v-if="list.hospitalName"
                    v-for="list in hosList"
                    :value="list.hospitalName">
                  {{list.hospitalName}}
                </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="开户行" prop="bankName">
                <a-input :disabled="isShowBtn" style="width: 350px" v-model="addForm.bankName" placeholder="请输入"/>
              </a-form-model-item>
              <a-form-model-item label="卡号" prop="bankCard">
                <a-input :disabled="isShowBtn" style="width: 350px" v-model="addForm.bankCard" placeholder="请输入"/>
              </a-form-model-item>
              <a-form-model-item label="身份证正反面" prop="idCardImg">
                <div style="display: flex;width: 450px;">
                  <div class="uploadImg">
                    <input
                        :disabled="isShowBtn"
                        id="identityCard"
                        style="width: 100px;height: 100px;opacity: 0;"
                        accept="image/*"
                        type="file"
                        @change="upCardImg"
                        ref="identityCardRef" />
                  </div>
                  <div class="upload_poster" v-for="(list,index) in addForm.idCardImg">
                    <img alt=""
                         style="width: 100%;height:100%"
                         :src="list"
                    />
                  </div>
                </div>
              </a-form-model-item>
              <a-form-model-item label="资格证" prop="nvqUrl">
                <div style="display: flex;width: 450px;">
                  <div class="uploadImg" >
                    <input
                        :disabled="isShowBtn"
                        id="certificationId"
                        style="width: 100px;height: 100px;opacity: 0;"
                        accept="image/*"
                        type="file"
                        @change="upNvqUrl"
                        ref="certificationRef" />
                  </div>
                  <div class="upload_poster" v-if="addForm.nvqUrl">
                    <img
                        alt=""
                        style="width: 100%;height:100%"
                        :src="addForm.nvqUrl"
                    />
                  </div>
                </div>
              </a-form-model-item>
              <div style="display: flex;justify-content: right">
                <a-button v-if="isShowBtn" @click="addCompile" type="primary">编辑</a-button>
                <a-button v-if="!isShowBtn" @click="closeAddDrawer">取消</a-button>
                <a-button v-if="!isShowBtn" @click="addAddSubmit" style="margin-left: 10px" type="primary">保存</a-button>
              </div>
            </a-form-model>
            <a-form-model
                ref="ruleFormAddTwo"
                :model="addTwoForm"
                :rules="addTwoRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
              <div style="height: 50px;border-top: 1px solid #e7e5e5;font-size: 16px;font-weight: bold;margin-top: 20px;padding-top: 20px;margin-bottom: 20px">
                付款信息
              </div>
              <a-form-model-item label="金额" prop="money">
                <a-input default-value="mysite" style="width: 350px" v-model="addTwoForm.money" placeholder="请输入">
                  <template slot="addonAfter"><span>元</span></template>
                </a-input>
              </a-form-model-item>
              <a-form-model-item label="劳务协议" prop="agreementPdf">
                <div class="uploadVideo" >
                  <a-icon type="upload" /> 上传文件
                </div>
                <input
                    id="agreementId"
                    style="width:120px;height: 40px;opacity: 0;position: absolute;top: 0;"
                    accept=".pdf"
                    type="file"
                    @change="content_pdf"
                    ref="agreementRef" />
                <div>
                  <div style="color: #8c8a8a;">支持扩展名：pdf</div>
                  <div v-if="addTwoForm.agreementPdf" style="font-size: 10px;color: #8c8a8a;margin-top: -10px">
                    <a-icon type="link" style="margin-right: 10px;font-size: 14px"/>
                    {{addTwoForm.agreementPdf}}
                    <a-icon @click="reduceBtn()" style="margin-left: 10px;font-size: 16px;cursor: pointer" type="close-circle" />
                  </div>
                </div>
              </a-form-model-item>
              <div style="display: flex;justify-content: right">
                <a-button v-if="titleVisible === '新增明细'" @click="addTwoAddSubmit">提交并继续</a-button>
                <a-button v-else @click="closeAddDrawerClose">取消</a-button>
                <a-button @click="addTwoSubmit" style="margin-left: 10px" type="primary">提 交</a-button>
              </div>
            </a-form-model>
          </div>
        </a-drawer>
<!--         复核    -->
      <a-drawer
            title="复核"
            placement="right"
            :closable="false"
            :visible="checkVisible"
            width="550px"
            @close="closeDrawerCheck"
        >
          <div class="checkDiv">
            <div>
              <div class="checkTitle">会议信息</div>
              <div>
                <p>会议名称： {{this.$route.query.title}}</p>
                <p>会议时间： {{this.$route.query.time}}</p>
                <p v-if="this.$route.query.type*1 === 2">会议海报：
                  <a v-if="LiveInfo.descImageUrl" @click="openModalDescImg">已上传</a>
                  <span v-else >未上传</span>
                </p>
                <p v-if="this.$route.query.type*1 === 2">会议视频：
                  <a v-if="LiveInfo.videoId" @click="openModalVideo">已上传</a>
                  <span v-else>未上传</span>
                </p>
              </div>
            </div>
            <div style="border-top: 1px solid #f5f5f5;border-bottom: 1px solid #f5f5f5">
              <div class="checkTitle">讲者信息</div>
              <div style="display: flex">
                <div style="width: 45%">
                  <p>姓名： {{perInfo.name}}</p>
                  <p>身份证号： {{perInfo.idCard}}</p>
                  <p>开户行： {{perInfo.bankName}}</p>
                  <p>付款金额：
                    <span style="width: 200px;height: 30px;line-height: 30px;background-color: #f6d7d0;color: #f17042;border: 1px solid #f17042;border-radius: 5px">
                      {{perInfo.money}} 元
                    </span></p>
                  <p>身份证： <a @click="openNotificationIdCardImg('topLeft',perInfo.idCardImg,'身份证')">已上传</a></p>
                  <p>资格证： <a @click="openNotification('topLeft',perInfo.nvqUrl,'资格证')">已上传</a></p>
                  <p>劳务协议： <a @click="agreementOpen(perInfo.agreementPdf)">已上传</a></p>
                </div>
                <div style="width: 55%">
                  <p>手机号： {{perInfo.tel}}</p>
                  <p>医院： {{perInfo.hospitalName}}</p>
                  <p>卡号： {{perInfo.bankCard}}</p>
                </div>
              </div>
            </div>
            <div style="border-bottom: 1px solid #f5f5f5;margin-bottom: 20px">
              <div class="checkTitle">复核</div>
              <a-form-model
                  ref="ruleForm_check"
                  :model="re_checkForm"
                  :rules="re_checkRules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
              >

                <a-form-model-item label="复核结果" prop="result">
                  <a-radio-group v-model="re_checkForm.result" name="radioGroup" @change="onChange">
                    <a-radio :value="1">
                      通过
                    </a-radio>
                    <a-radio :value="2">
                      驳回
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="re_checkForm.result === 2" label="复核意见" prop="opinion" >
                  <a-textarea v-model="re_checkForm.opinion" placeholder="请输入" :rows="6" />
                </a-form-model-item>
                <a-form-model-item>
                  <a-alert
                      v-if="re_checkForm.rejectReason && re_checkForm.result === 2"
                      style="margin-left: 20px;width: 450px"
                      message="上次驳回原因"
                      :description="re_checkForm.rejectReason"
                      type="info"
                      show-icon
                      closable
                  />
                </a-form-model-item>
              </a-form-model>
            </div>
<!--            <div style="margin-top: 20px">-->
<!--              <div class="checkTitle">凭证</div>-->
<!--              <div>凭证： <a>已上传</a></div>-->
<!--            </div>-->
          </div>
          <div
              :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
          >
            <a-button style="marginRight: 8px" @click="closeDrawerCheck">
              取消
            </a-button>
            <a-button type="primary" @click="CheckSubmit">
              确认
            </a-button>
          </div>
        </a-drawer>
        <!--         查看    -->
        <a-drawer
            title="查看"
            placement="right"
            :closable="false"
            :visible="checkLookVisible"
            width="550px"
            @close="closeLookDrawerCheck"
        >
          <div class="checkDiv">
            <div>
              <div class="checkTitle">会议信息</div>
              <div>
                <p>会议名称： {{this.$route.query.title}}</p>
                <p>会议时间： {{this.$route.query.time}}</p>
                <p v-if="this.$route.query.type*1 === 2">会议海报：
                  <a v-if="LiveInfo.descImageUrl" @click="openModalDescImg">已上传</a>
                  <span v-else >未上传</span>
                </p>
                <p v-if="this.$route.query.type*1 === 2">会议视频：
                  <a v-if="LiveInfo.videoId" @click="openModalVideo">已上传</a>
                  <span v-else>未上传</span>
                </p>
              </div>
            </div>
            <div style="border-top: 1px solid #f5f5f5;border-bottom: 1px solid #f5f5f5">
              <div class="checkTitle">讲者信息</div>
              <div style="display: flex">
                <div style="width: 45%">
                  <p>姓名： {{perInfo.name}}</p>
                  <p>身份证号： {{perInfo.idCard}}</p>
                  <p>开户行： {{perInfo.bankName}}</p>
                  <p>付款金额：
                    <span style="width: 200px;height: 30px;line-height: 30px;background-color: #f6d7d0;color: #f17042;border: 1px solid #f17042;border-radius: 5px">
                      {{perInfo.money}} 元
                    </span></p>
                  <p>身份证：
<!--                    <span v-if="perInfo.idCardImg">未上传</span>-->
                    <a @click="openNotificationIdCardImg('topLeft',perInfo.idCardImg,'身份证')">已上传</a>
                  </p>
                  <p>资格证：
                    <a @click="openNotification('topLeft',perInfo.nvqUrl,'资格证')">已上传</a>
                  </p>
                  <p>劳务协议： <a @click="agreementOpen(perInfo.agreementPdf)">已上传</a></p>
                </div>
                <div style="width: 55%">
                  <p>手机号： {{perInfo.tel}}</p>
                  <p>医院： {{perInfo.hospitalName}}</p>
                  <p>卡号： {{perInfo.bankCard}}</p>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;border-bottom: 1px solid #f5f5f5" v-if="re_checkForm.result === 1 || re_checkForm.result === 2" >
              <div class="checkTitle">复核结果</div>
              <div style="margin-bottom: 15px;">
                结果： <span style="color: red" v-if="re_checkForm.result === 2">已驳回</span>
                <span v-if="re_checkForm.result === 1">已通过</span></div>
              <div style="margin-bottom: 20px" v-if="re_checkForm.result === 2">
                意见：<span style="color: red">{{re_checkForm.opinion}}</span>
              </div>
            </div>
            <div style="margin-top: 20px;margin-bottom: 80px;">
              <div v-if="re_checkForm.result === 3 || re_checkForm.result === 4">
                <div class="checkTitle">打款结果</div>
                <!--              只有打款成功和打款失败时显示-->
                <div>
                  结果： <span v-if="re_checkForm.result === 4" style="color: red">打款失败</span>
                  <span v-if="re_checkForm.result === 3">打款成功</span>
                </div>
              </div>
              <div style="margin-top: 15px" v-if="perInfo.voucher">凭证：
                <a @click="openNotification('topLeft',perInfo.voucher,'凭证')">查看凭证</a>
              </div>
            </div>

          </div>
          <div
              :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
          >
            <a-button style="marginRight: 8px" @click="closeLookDrawerCheck">
              取消
            </a-button>
            <a-button type="primary" @click="CheckLookSubmit">
              确认
            </a-button>
          </div>
        </a-drawer>
<!--        上传凭证  -->
      <a-drawer
            title="上传凭证"
            placement="right"
            :closable="false"
            :visible="CredentialsVisible"
            width="550px"
            @close="closeDrawerCredentials"
        >
          <a-form-model
              ref="ruleForm_Credentials"
              :model="CredentialsForm"
              :rules="CredentialsRules"
              :label-col="labelCol1"
              :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="结果" prop="result">
              <a-radio-group v-model="CredentialsForm.result" name="radioGroup" >
                <a-radio :value="3">
                  打款成功
                </a-radio>
                <a-radio :value="4">
                  打款失败
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
<!--            <a-form-model-item label="上传凭证" prop="opinion">-->
<!--              <a-textarea v-model="CredentialsForm.opinion" placeholder="请输入" :rows="6" />-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="上传凭证" prop="opinion">
              <div style="display: flex;width: 450px;">
                <div class="uploadImg">
                  <input
                      id="idOpinion"
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="upOpinionImg"
                      ref="opinionRef" />
                </div>
                <div class="upload_poster" v-for="(list,index) in CredentialsForm.opinion">
                  <img alt=""
                       style="width: 100%;height:100%"
                       :src="list"
                  />
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
          <div
              :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
          >
            <a-button style="marginRight: 8px" @click="closeDrawerCredentials">
              取消
            </a-button>
            <a-button type="primary" @click="addSubmitCredentials">
              确认
            </a-button>
          </div>
        </a-drawer>
<!--        查看海报-->
        <a-modal width="600px" a-modal v-model="visiblePoster" title="海报" @ok="handleOkPoster">
<!--          <div style="display: flex;justify-content: space-around;width: auto">-->
            <img
                v-for="itemHb in LiveInfo.descImageUrl"
                style="width:99%;height: auto;"
                :src="itemHb" >
<!--          </div>-->
        </a-modal>
<!--        查看视频-->
        <a-modal a-modal v-model="visibleVideo" title="视频" @ok="handleOkVideo">
          <video v-for="itemVideo in LiveInfo.videoId" width="99%" controls>
            <source :src="itemVideo" type="video/mp4"/>
          </video>
        </a-modal>
      <div style="height: 100px"></div>
      <div
          style="
          width:87%;
          height:50px;
          position: fixed;
          bottom:50px;
          background-color: #f0f2f5;
          border-bottom: 1px solid #fff;
          padding-top: 10px">
        <div style="width: 85%;margin: auto;display: flex;justify-content: space-between;">
          <a-button @click="returnBtn">返回上一层</a-button>
          <div style="display: flex">
            <!--创建人：-->
            <span v-if="userId*1 === createdById*1">
              <a-button @click="CompleteData(1)" type="primary" style="margin-right: 20px">完成打款</a-button>
              <a-button @click="CompleteData(2)" type="primary">完成会议资料</a-button>
            </span>
            <!--资料负责人-->
            <a-button @click="CompleteData(2)" v-else type="primary">完成会议资料</a-button>
          </div>
        </div>
      </div>
      </div>
    </a-spin>
  </div>
</template>

<script>

import {
  delListPersons, ExportBulkList,
  get_signature,
  get_videoLink,
  getMemberListTel, hospitalByName,
  listPersons, otherManageList, postListPersons,
  postMember, putListPersons, putLogMeetExpert,
  putMember, selMtOtDetail
} from "@/service/MedicalConference_y";
import headerConfig from '@/service/api_header_config'
const columns = [
  {
    title: '姓名',
    dataIndex: 'memberName',
    key: 'memberName',
    // width: "15%",
  },{
    title: '身份证号',
    dataIndex: 'memberIdCard',
    key: 'memberIdCard',
    width:'15%',
  },
  {
    title: '手机号',
    dataIndex: 'memberTel',
    key: 'memberTel',
    // width:'15%',
    scopedSlots: { customRender: 'memberTel' },
  },
  {
    title: '医院',
    dataIndex: 'memberHospital',
    key: 'memberHospital',
    ellipsis: true,
    width:'15%',
    scopedSlots: { customRender: 'hos' },
  },
  //  0:待审批 1:已通过 2:已驳回 3:打款成功 4:打款失败
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    ellipsis: true,
    scopedSlots: { customRender: 'status' },
  },
  // {
  //   title: '凭证',
  //   dataIndex: 'voucher',
  //   key: 'voucher',
  //   ellipsis: true,
  //   scopedSlots: { customRender: 'voucher' },
  // },
  {
    title: '金额',
    dataIndex: 'money',
    key: 'money',
    ellipsis: true,
    width:'6%',
    scopedSlots: { customRender: 'money' },
  },{
    title: '提交时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    width:'15%',
    // scopedSlots: { customRender: 'createdTime' },
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    width:'15%',
    align:'center',
    scopedSlots: { customRender: 'system' },
  },
];

const data = [];
import {admin_icon} from "@/utils/myIcon_f";
import {update_File,update} from "@/utils/update";
export default {
  name: "DetailsMeetings",
  components:{
    admin_con: admin_icon,
  },
  data(){
    return {
      renYuanId:{},
      addOrRest:null,
      titleVisible:'新增明细',
      writeBackImg:require('@/assets/img_3.png'),
      // City:City,
      visible:false,
      spinning:false,
      titleData:'',
      form: {
        descImageUrl: [],
        video: [],
        meet: '',
      },
      rules: {
        descImageUrl: [{required: true, message: '请上传', trigger: 'change'}],
        video: [{required: true, message: '请上传', trigger: 'change'}],
        meet: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      labelCol1:{span:4},
      labelCol:{span:5},
      wrapperCol:{span:14},
      PerList:[
        {
          id:1,
          name:'111',
        }
      ],
      columns,
      data,
      page_no:1,
      tableCount:0,
      addVisible:false,
      addForm:{
        name:'',
        tel:'',
        idCard:'',
        hospitalName:undefined,
        province:undefined,
        bankCard:'',
        bankName:'',
        // money:'',
        idCardImg:[],
        nvqUrl:'',
        // agreementPdf:'',
      },
      // hospitaloPtions: [],
      addTwoForm:{
        money:'',
        agreementPdf:'',
      },
      addRules: {
        name:[{required: true, message: '请输入', trigger: 'blur'}],
        tel:[{required: true, message: '请输入', trigger: 'blur'}],
        //校验身份证号的长度
        idCard:[
          {required: true, message: '请输入', trigger: 'blur'},
          { min: 18, max: 18, message: '身份证号长度为18位', trigger: 'blur' },
        ],
        hospitalName:[{required: true, message: '请选择', trigger: 'change'}],
        bankCard:[{required: true, message: '请输入', trigger: 'blur'}],
        bankName:[{required: true, message: '请输入', trigger: 'blur'}],
        // money:[{required: true, message: '请输入', trigger: 'blur'}],
        idCardImg:[{required: true, message: '请上传', trigger: 'change'}],
        nvqUrl:[{required: true, message: '请上传', trigger: 'change'}],
        // agreementPdf:[{required: true, message: '请上传', trigger: 'change'}]
      },
      addTwoRules: {
        money:[{required: true, message: '请输入', trigger: 'blur'}],
        agreementPdf:[{required: true, message: '请上传', trigger: 'change'}]
      },
      fileId: '', // 文件id
      vFile: {}, // 视频文件File对象
      vIsFileExist: false, // File对象是否存在
      vUploader: {}, // 腾讯云上传的初始化对象
      signature:'',

      hosList:[],
      hos_select_page_no:1,
      selectTitleHosName:'',
      checkVisible:false,
      reCheckId:null,
      re_checkForm:{
        result:undefined,
        opinion:'',
        rejectReason:'',
      },
      re_checkRules:{},
      //通过
      re_checkRulesTrue:{
        result: [{required: true, message: '请选择', trigger: 'blur'}],
      },
      //不通过
      re_checkRulesFalse:{
        result: [{required: true, message: '请选择', trigger: 'blur'}],
        opinion: [{required: true, message: '请输入', trigger: 'blur'}]
      },
      CredentialsVisible:false,
      CredentialsForm:{
        result:undefined,
        opinion:[]
      },
      uploadCredentialsId:null,
      CredentialsRules:{
        result: [{required: true, message: '请选择', trigger: 'blur'}],
        opinion: [{required: true, message: '请输入', trigger: 'blur'}]
      },
      isShowBtn:true,
      perIsTrue:null,
      checkLookVisible:false,
      perInfo:{
        money:'',
        bankCard: "",
        bankName: "",
        hospitalName: "",
        idCard: "",
        //身份证正反照片
        idCardImg:[],
        name: "",
        //资格证照片
        nvqUrl: '',
        tel: "",
      },
      LiveInfo:{},
      LiveInfoIsSahow:null,
      videoUrl:'',
      userId:null,
      createdById:null,
      externalId:null,
      visiblePoster:false,
      visibleVideo:false,
      MtOtDetail:[],
    }
  },
  created() {
    //获取用户登录的id
    //1.获取本地存储内容
    const user_info = window.localStorage.getItem("user_info");
    this.userId = JSON.parse(user_info).id
    this.createdById = this.$route.query.createdById
    this.externalId = this.$route.query.externalId

    this.getList()
    this.getDetail()
    this.getLiveInfo()
  },
  methods:{
    async getLiveInfo() {
      if(this.$route.query.type*1 === 2){
        let data = {
          page_no:1,
          page_size:10,
          id:this.$route.query.id,
        }
        const response = await otherManageList(data)
        if(response.code === 0){
          this.LiveInfo = response.data[0]
          let arr = response.data
          if(arr[0].descImageUrl && arr[0].videoId){
            this.LiveInfoIsSahow = true
          }else {
            this.LiveInfoIsSahow = false
          }
        }
      }else if(this.$route.query.type*1 === 1){
        this.LiveInfoIsSahow = true
      }
    },
    async getDetail() {
      let data = {
        type:this.$route.query.type,
        meetingid:this.$route.query.id,
      }
      const response = await selMtOtDetail(data)
      if(response.code === 0){
        this.MtOtDetail = response.data[0]
      }
    },
    //获取省份城市医院
    // getPCH(){
    //   let province = []
    //   this.City.forEach((item,index)=>{
    //     let city = []
    //     province.push({
    //       value:item.name,
    //       label:item.name,
    //       children:city
    //     })
    //     item.cityList.forEach((list)=>{
    //       city.push({
    //         value:list.name,
    //         label:list.name,
    //         loading:false,
    //       })
    //     })
    //     province[index].children = city
    //   })
    //   this.hospitaloPtions = province
    // },
    async getList(state) {
      let data = {
        meetingid:this.$route.query.id,
        type:this.$route.query.type,
        name:this.titleData,
        state:state,
        page_no:this.page_no,
        page_size:10
      }
      const response = await listPersons(data)
      if (response.code === 0) {
        this.data = response.data
        this.tableCount = response.count
      }else {
        this.$message.warning(response.message)
      }
    },
    stateBtn(state){
      this.getList(state)
    },
    pressEnter(){
      this.page_no = 1
      this.getList()
    },
    closeDrawer(){
      this.visible = false
    },
    addSubmit(){
      this.$refs.ruleFormHui.validate(async valid => {
        if (valid) {
          let form = this.form
          let data = {
            "id":this.$route.query.id,
            "descImageUrl":form.descImageUrl,
            "videoId":form.video,
            "summary":form.meet,
            //"0:待收集材料 1:已完成 2:待打款"
            "state":this.$route.query.state
          }
          await this.meetingMaterial(data)
          //获取海报视频
          await this.getLiveInfo()
          this.$refs.ruleFormHui.resetFields()
          this.visible = false
        } else {
          return false;
        }
      });
    },
    CompleteData(state){
      let data = {
        "id":this.$route.query.id,
        "state":state
      }
      this.meetingMaterial(data)
    },
    //修改其他会议管理---上传会议资料
    async meetingMaterial(data) {
      const response = await putListPersons(data)
      if (response.code === 0) {
        this.$message.success("操作成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    popupScrollPer(){

    },
    selectInput(){

    },seachBtn(){

    },resetBtn(){

    },
    addBtn(){
      this.getHosNameList()
      this.addOrRest = true
      this.titleVisible = '新增明细'
      this.addVisible = true
    },
    compileBtn(row){
      this.renYuanId = row
      this.getHosNameList()
      this.addOrRest = false
      this.titleVisible = '编辑明细'
      this.addVisible = true
      this.echo(row.memberTel)
      this.addTwoForm.money = row.money
      this.addTwoForm.agreementPdf = row.agreementPdf
    },
    paginationBtn(){

    },openDel(){
      this.$router.push({path:'detailsMeetings'})
    },
    returnBtn(){
      window.history.back()
    },
    addAddSubmit(){
      this.$refs.ruleFormAdd.validate(valid => {
        if (valid) {
          let form = this.addForm
          //判断身份证图片必须大于等于两张
          if(form.idCardImg.length >= 2){
            //  两种情况 1.修改 2.新增
            //  通过判断查询的手机号的用户是否存在，存在即修改，不存在即新增
            let dataRest = {
              "name": form.name,
              "tel": form.tel,
              "idCard":form.idCard,
              // "province": form.province[0],
              // "city": form.province[1],
              "hospitalName": form.hospitalName,
              "bankName": form.bankName,
              "bankCard": form.bankCard,
              "idCardImg": form.idCardImg,
              "nvqUrl": form.nvqUrl,
            }
            if(form.id){
              //  修改
              this.putList(form.id,dataRest)
            }else {
              //  新增
              this.postList(dataRest)
            }
            this.isShowBtn = true
          }else {
            this.$message.warning("身份证正反面必须至少上传两张！")
          }
        }
        else {
          return false;
        }
      });
    },
    //  新增
    async postList(data) {
      const response = await postMember(data)
      if(response.code === 0){
        this.$message.success("新增成功~")
        await this.echo(this.addForm.tel)
      }else {
        this.$message.warning(response.message)
      }
    },
    //  修改
    async putList(id,data) {
      const response = await putMember(id,data)
      if(response.code === 0){
        this.$message.success("修改成功~")
        //  刷新列表
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    closeAddDrawer(){
      this.isShowBtn = true
      // this.addVisible = false
    },
    closeAddDrawerClose(){
      //  关闭对话框
      this.addVisible = false
      this.isShowBtn = true
      //  清除
      this.$refs.ruleFormAdd.resetFields()
      this.$refs.ruleFormAddTwo.resetFields()
      this.addTwoForm.money = ''
      this.addTwoForm.agreementPdf = ''
      this.hosList = []

    },
    async upDescImageUrl() {
      let inputDOM = this.$refs.descImageUrl.files[0];
      //判断图片大小
      // if (inputDOM.size < 3145728) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          let arr = this.form.descImageUrl
          arr.push(image.data.url)
          document.getElementById('descImageUrlId').value = null;
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
    },
    async upCardImg() {
        let inputDOM = this.$refs.identityCardRef.files[0];
        //判断图片大小
        const image = await update(inputDOM)
        if (image.code === 0) {
          let arr=this.addForm.idCardImg;
          arr.push(image.data.url)
          // document.getElementById('identityCard').value = null;
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
    },
    async upOpinionImg() {
      let inputDOM = this.$refs.opinionRef.files[0];
        const image = await update(inputDOM)
        if (image.code === 0) {
          let arr = this.CredentialsForm.opinion;
          arr.push(image.data.url)
          // document.getElementById('identityCard').value = null;
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
    },
    async upNvqUrl() {
      let inputDOM = this.$refs.certificationRef.files[0];
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.addForm.nvqUrl = image.data.url
          document.getElementById('certificationId').value = null;
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }

    },
    async upVideo(e) {
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息
          document.getElementById('up_video').value = null;
          await this.getSignature()
          await this.uploadVideoFile()
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      } else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
    },
    //获取签名
    async getSignature() {
      const response = await get_signature()
      if (response.code === 0) {
        this.signature = response.data.signature;
      }
    },
    // 上传腾讯云
    uploadVideoFile(){
      let _this = this
      const getSignature = () => {
        return _this.signature;
      }
      const tcVod = new _this.$TcVod({
        getSignature: getSignature // 前文中所述的获取上传签名的函数
      })
      const mediaFile = this.vFile
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      this.vUploader.done().then(res => { // 上传完成回调
        //视频上传后获取到的视频fileId
        // this.form.video = res.fileId
        this.content_video_url2(res.fileId)
        this.$message.success('视频文件上传成功')
      })
    },
    //获取视频url地址1
    async content_video_url2(videoId) {
      const response = await get_videoLink(videoId)
      if (response.code === 0) {
        // this.videoUrl = response.data.mediaUrl
        let arr = this.form.video
        arr.push(response.data.mediaUrl)
      }else {
        this.$message.warning(response.message)
      }
    },
    // //获取视频url地址2
    // async content_video_url(videoId) {
    //   const response = await get_videoLink(videoId)
    //   if (response.code === 0) {
    //     this.videoUrl = response.data.mediaUrl
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    onSearch(value) {
      if(value.length === 11){
        this.echo(value)
      }else {
        this.$message.warning("请规范输入手机号~")
      }
    },
    //
    async echo(value) {
      let data = {
        tel: value,
        type: parseInt(this.$route.query.type),
        meetingId: parseInt(this.$route.query.id),
      }
      const response = await getMemberListTel(data)
      if (response.code === 0) {
        if (response.data.length) {
          //  获取用户是否存在
          //  赋值
          this.addForm = response.data[0]
          // this.addForm.province = [response.data[0].province, response.data[0].city]
          if (response.data[0].idCardImg === null) {
            this.addForm.idCardImg = []
          }
        } else {
          this.$message.warning("该用户不存在请添加~")
          this.$refs.ruleFormAdd.resetFields()
          this.addForm.id = ''
          this.addForm.tel = value
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    //通过手机号查询会员信息
    telPer(){

    },

    closeDrawerCheck(){
      this.checkVisible = false
      this.$refs.ruleForm_check.resetFields()
    },
    CheckSubmit(){
      this.$refs.ruleForm_check.validate(async valid => {
        if (valid) {
          let form = this.re_checkForm;
          let data = {
            "id": this.reCheckId,
            // ["付款凭证图片路径", "付款凭证图片路径", "付款凭证图片路径"],
            // "voucher":form.opinion,
            "rejectReason":form.opinion,
            //"0:待审批 1:已通过 2:已驳回 3:打款成功 4:打款失败"
            "state": form.result
          }
          await this.UploadCredential(data)
          await this.getLiveInfo()
          await this.getDetail()
          this.checkVisible = false
          this.$refs.ruleForm_check.resetFields()
        } else {
          return false;
        }
      });
    },
    CheckLookSubmit(){
      this.checkLookVisible = false
    },
    closeDrawerCredentials(){
      this.CredentialsVisible = false
      this.$refs.ruleForm_Credentials.resetFields()
    },
    addSubmitCredentials(){
      this.$refs.ruleForm_Credentials.validate(async valid => {
        if (valid) {
          let form = this.CredentialsForm;
          let data = {
            "id": this.uploadCredentialsId,
            // ["付款凭证图片路径", "付款凭证图片路径", "付款凭证图片路径"],
            "voucher":form.opinion,
            // "rejectReason": "驳回原因",
            //"0:待审批 1:已通过 2:已驳回 3:打款成功 4:打款失败"
            "state": form.result
          }
          await this.UploadCredential(data)
          await this.getLiveInfo()
          this.CredentialsVisible = false
          this.$refs.ruleForm_Credentials.resetFields()
        } else {
          return false;
        }
      });
    },
    //内容-PDF展示--------------------------------------------------------------
    async content_pdf() {
      let inputDOM = this.$refs.agreementRef.files[0];
      const fileVal = await update_File(inputDOM)
      if (fileVal.code === 0) {
        this.addTwoForm.agreementPdf = fileVal.data.url
        this.$message.success("上传成功")
        document.getElementById('agreementId').value = null
      } else {
        this.$message.warning(fileVal.message)
      }
    },
    addCompile(){
      this.isShowBtn = false
    },
    //提交并继续-清空内容并继续新增
    addTwoAddSubmit(){
      this.$refs.ruleFormAdd.validate(valid => {
        if (valid) {
          this.$refs.ruleFormAddTwo.validate(valid => {
            if (valid) {
              //判断当前有没有添加人员名单，
              if(this.addForm.id){
                if(this.isShowBtn){
                  let form = this.addTwoForm
                  //  新增
                  let data = {
                    "memberId": this.addForm.id,
                    "type":this.$route.query.type,
                    "meetingId": this.$route.query.id,
                    "money": form.money,
                    "agreementPdf": form.agreementPdf
                  }
                  this.postListPer(data)
                  this.$refs.ruleFormAddTwo.resetFields()
                  this.$refs.ruleFormAdd.resetFields()
                }else {
                  this.$message.warning("请先保存用户基本信息。用户基本信息保存之后，才能提交付款信息~")
                }
              }else {
                this.$message.warning("请先添加用户基本信息~")
              }
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
    async lookPer(row) {
      //  审核结果与意见
      this.re_checkForm.opinion = row.rejectReason
      this.re_checkForm.result = row.state
      this.checkLookVisible = true
      let data = {
        tel: row.memberTel,
      }
      const response = await getMemberListTel(data)
      if (response.code === 0) {
        //  赋值
        this.perInfo = response.data[0]
        this.perInfo.money = row.money
        this.perInfo.agreementPdf = row.agreementPdf
        this.perInfo.voucher = row.voucher
      }
    },
    agreementOpen(url){
      window.open(url)
    },
    addTwoSubmit(){
      this.$refs.ruleFormAdd.validate(valid => {
        if (valid) {
          this.$refs.ruleFormAddTwo.validate(valid => {
            if (valid) {
            //判断当前有没有添加人员名单，
            if(this.addForm.id){
              if(this.isShowBtn){
                let form = this.addTwoForm
                //判断是新增还是修改addOrRest:true--新增  false:修改
                if(this.addOrRest){
                  //  新增
                  let data = {
                    "memberId": this.addForm.id,
                    "type":this.$route.query.type,
                    "meetingId": this.$route.query.id,
                    "money": form.money,
                    "agreementPdf": form.agreementPdf
                  }
                  this.postListPer(data)
                }else {
                  //  修改
                  let dataRest = {
                    "id": this.renYuanId.id,
                    "money": form.money,
                    "agreementPdf": form.agreementPdf,
                    //编辑之后状态应该更新为待审核
                    // "state": this.renYuanId.state
                    "state": 0
                  }
                  this.putListPer(dataRest)
                }
                //  关闭对话框
                this.addVisible = false
                this.isShowBtn = true
                //  清除
                this.$refs.ruleFormAddTwo.resetFields()
                this.$refs.ruleFormAdd.resetFields()
                this.hosList = []
              }else {
                this.$message.warning("请先保存用户基本信息。用户基本信息保存之后，才能提交付款信息~")
              }
          }else {
            this.$message.warning("请先添加人员~")
          }
        } else {
          return false;
        }
      });
        } else {
          return false;
        }
      });
    },
    //添加人员名单
    async postListPer(data) {
      const response = await postListPersons(data)
      if (response.code === 0) {
        this.$message.success("新增成功")
      //  刷新列表
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    }, //修改人员名单
    async putListPer(data) {
      const response = await putLogMeetExpert(data)
      if (response.code === 0) {
        this.$message.success("修改成功")
      //  刷新列表
        await this.getList()
        await this.getDetail()
      } else {
        this.$message.warning(response.message)
      }
    },
    // provinceChange(value) {
    //   let data = {
    //     province:value[0],
    //     city:value[1],
    //     pagenum:this.hospitalNameList_pageNo
    //   }
    //   this.getHosNameList(data)
    // },

    //获取医院列表
    async getHosNameList() {
      const data = {
        hospitalName:this.selectTitleHosName,
        page_no:this.hos_select_page_no,
        page_size:20,
      }
      const response = await hospitalByName(data)
      if(response.code === 0){
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.hosList.push({
            id: list[i].id,
            hospitalName: list[i].hospitalName,
          })
        }
      }
    },
    popupScroll(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.hos_select_page_no++
        this.getHosNameList()
      }
    },
    selectAddSearch(value){
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.hosList = []
      this.selectTitleHosName = value
      this.getHosNameList()
    },
    closeLookDrawerCheck(){
      this.checkLookVisible = false
    },
    async reCheckBtn(row) {
      this.reCheckId = row.id
      this.re_checkForm.opinion = row.rejectReason
      this.re_checkForm.result = row.state
      //判断是不是通过，通过复核意见不显示，其他显示
        if(row.state === 1){
          this.re_checkRules = this.re_checkRulesFalse
        }else {
          this.re_checkRules = this.re_checkRulesTrue
        }
      this.re_checkForm.rejectReason = row.rejectReason
      this.checkVisible = true
      let data = {
        tel: row.memberTel,
      }
      const response = await getMemberListTel(data)
      if (response.code === 0) {
        //  赋值
        this.perInfo = response.data[0]
        this.perInfo.money = row.money
        this.perInfo.agreementPdf = row.agreementPdf
      }
    },
    async UploadCredentials(record) {
      this.uploadCredentialsId = record.id
      this.CredentialsForm.result = record.state
      if(record.voucher){
        this.CredentialsForm.opinion = record.voucher
      }
      this.CredentialsVisible = true
    },
    //  右侧弹窗--单张图片
    async openNotification(placement,img,title) {
      this.$notification.open({
        message: title,
        description:
            <div style="height:600px;overflow-y:scroll">
              <div>
                <img src={`${img ? img:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>
            </div>,
        style:{
          "width":"600px",
          "min-height":"400px"
        },
        placement,
        duration: 0,
      });
    }, //  右侧弹窗--身份证
    async openNotificationIdCardImg(placement,img,title) {
      this.$notification.open({
        message: title,
        description:
            <div style="height:600px;overflow-y:scroll">
              <div>
                <img src={`${img[0] ? img[0]:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
                <img src={`${img[1] ? img[1]:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>
            </div>,
        style:{
          "width":"600px",
          "min-height":"400px"
        },
        placement,
        duration: 0,
      });
    },
    upLiveInfo(){
      this.visible = true
    },
    async confirm(id) {
      const response = await delListPersons(id)
      if (response.code === 0) {
        this.$message.success("删除成功~")
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    async UploadCredential(data) {
      const response = await putLogMeetExpert(data)
      if (response.code === 0) {
        this.$message.success("上传成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async ExportBulk() {
      let data = {
        meetingid:this.$route.query.id,
        // ExelName:'会议结算人员名单'
      }
      const header = headerConfig.api_header
      window.open(header + '/yun/LogMeetExpertExcel?meetingid='+this.$route.query.id+'&type='+this.$route.query.type)
    },
    handleOkPoster(){

    },
    handleOkVideo(){

    },
    openModalDescImg(){
      this.visiblePoster = true
    },
    openModalVideo(){
      this.visibleVideo = true
    },
    reduceBtnVideo(index){
      this.form.video.splice(index,1)
    },
    reduceBtn(){
      this.addTwoForm.agreementPdf = ''
    },
    onChange(e){
      if(e.target.value === 1){
        this.re_checkRules = this.re_checkRulesTrue
      }else {
        this.re_checkRules = this.re_checkRulesFalse
      }
    },
  }
}
</script>

<style scoped lang="scss">
.topOne{
  height: 100px;
  border-bottom: 15px solid #f0f2f5;
  padding: 15px;
  .topOne-title{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .topOne-btn{
    border-radius:5px;
    width: 100px;
    height:25px;
    line-height:23px;
    text-align: center;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.liveMeet-top{
  width: 100%;
  height: 85px;
  border-bottom: 15px solid #f0f2f5;
  display: flex;
  .liveMeet-top-input{
    display: flex;
    justify-content: center;
    width: 30%;
    height: 50px;
    line-height: 30px;
    padding-top: 20px;
    .input{
      width: 70%;
      margin-left: 10px;
    }
  }
}
.activity-table{
  width: 100%;
  min-height: 600px;
  .activity-table-btn{
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .aleart{
      padding-left: 5px;
      height: 32px;
      width:auto;
      margin-right: 10px;
      line-height: 32px;
      background-color: #eafbff;
      border-radius: 5px;
      border: 1px solid #3a9af3;
    }
  }
  .icon{
    font-size: 8px;
    margin-right: 5px;
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.uploadVideo{
  border: #DDDDDD 1px solid;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  height: 40px;
  cursor: pointer;
  position: relative;
  .upVideoTitle{
    width: 120px;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 0;
    color: #8c8a8a;
  }
}
.upload_poster{
  border: #DDDDDD 2px dashed;
  width: 110px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
.checkDiv{
  width: 98%;
  margin: auto;
  .checkTitle{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>